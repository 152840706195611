import { Card, Image, Stack, Text, useMantineTheme } from "@mantine/core";
import React, { useRef } from "react";
import CountUp, { useCountUp } from "react-countup";
import { Link } from "react-router-dom";
const LandingPageStatsCards = ({ stat, entry, ref, containerRef }) => {
  const countUpRef = useRef(null);
  const { start, pauseResume, reset, update } = useCountUp({
    ref: countUpRef,
    start: 0,
    end: 1234567,
    delay: 1000,
    duration: 5,
    onReset: () => console.log("Resetted!"),
    onUpdate: () => console.log("Updated!"),
    onPauseResume: () => console.log("Paused or resumed!"),
    onStart: ({ pauseResume }) => console.log(pauseResume),
    onEnd: ({ pauseResume }) => console.log(pauseResume),
  });

  console.log("sss", stat);
  let icon =
    stat.title === "Available Venues"
      ? "venue"
      : stat.title === "Available Vendors"
      ? "vendor"
      : stat.title === "Total Venue Bookings"
      ? "venueB"
      : stat.title === "Total Vendor Bookings"
      ? "vendorB"
      : "";
  const card = (
    <Card
      ref={ref}
      className="border"
      radius={"md"}
      sx={{
        borderRadius: "0.5rem",
        ":hover": {
          boxShadow: "0 5px 12px #0003",
        },
        // currently the card is not clickable, so cursor is unset
        cursor: "unset",
        boxShadow: "0 2px 8px #00000026",
        transition: "box-shadow .2s",
        transitionDuration: "0.2s",
        transitionTimingFunction: "ease",
        transitionDelay: "0s",
        transitionProperty: "box-shadow",
      }}
      component={Link}
      style={{ width: "302px" }}
    >
      <Card.Section style={{ height: "201px", position: "relative" }}>
        <Stack
          spacing={0}
          ref={ref}
          style={{ height: "100%", width: "100%" }}
          align={"center"}
          justify={"center"}
        >
          {icon && (
            <Image
              src={require(`../../assets/${icon}.svg`)}
              withPlaceholder
              width={"50px"}
            />
          )}
          <CountUp
            className="fgColorF"
            style={{ fontSize: 40, fontWeight: 700, marginTop: 10 }}
            end={stat.value}
          />
          <Text weight={500} size={"xl"}>
            {stat.title}
          </Text>
        </Stack>
      </Card.Section>
    </Card>
  );

  return <div>{card}</div>;
};

export default LandingPageStatsCards;

// <Text> {venue.venueName}</Text>
// <Text>{venue?.subVenues?.length}</Text>
// <div ref={countUpRef} />
// <button onClick={start}>Start</button>
// <button onClick={reset}>Reset</button>
// <button onClick={pauseResume}>Pause/Resume</button>
// <button onClick={() => update(2000)}>Update to 2000</button>
// </Stack>
