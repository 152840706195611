import { Avatar, Blockquote, Box, Divider, Stack, Text } from "@mantine/core";
import qoute from "../../assets/qoutes.svg";
import { useMediaQuery } from "@mantine/hooks";

const CustomerTestimonialsCard = ({ testimonial }) => {
  const isMobile = useMediaQuery("(max-width: 720px)");
  return (
    <Box
      sx={{
        height: "306px",
        // width: "100%",
        boxSizing: "border-box",
        display: "flex",
        gap: "10px",
        alignItems: "center",
        transition: "transform .35s",
        marginRight: "10px",
      }}
    >
      {" "}
      <Stack spacing={"5px"}>
        <Divider
          orientation="vertical"
          size={"sm"}
          color="rgb(0, 0, 0, 0.2)"
          h={"70px"}
          m="auto"
        />
        <Avatar
          size={isMobile ? "lg" : "xl"}
          radius={"xl"}
          src={testimonial.user[0].profileImage}
          style={{ border: "3px solid white", borderRadius: "50%" }}
        />
        <Divider
          orientation="vertical"
          size={"sm"}
          color="rgb(0, 0, 0, 0.2)"
          h={"70px"}
          m="auto"
        />
      </Stack>
      <Box>
        <img
          src={qoute}
          width={"40px"}
          alt="qoutes"
          style={{ marginBottom: "20px" }}
        />
        <Text color={"dimmed"} lineClamp={4} size={"sm"} align="justify">
          {testimonial.feedback}
        </Text>
        <Stack spacing={0} mt="md">
          <Text
            lineClamp={1}
            weight={500}
            style={{ wordBreak: "break-all", flexWrap: "wrap" }}
          >
            {testimonial.user[0].name}
          </Text>
          <Text color={"dimmed"} lineClamp={1}>
            {testimonial.createdAt.split("T")[0]}
          </Text>
        </Stack>
      </Box>
    </Box>
  );
};

export default CustomerTestimonialsCard;
