import {
  Anchor,
  Button,
  Container,
  Divider,
  Group,
  Stack,
  Text,
} from "@mantine/core";
import { IconArrowRight } from "@tabler/icons";
import React from "react";
import { Link } from "react-router-dom";
import CustomerTestimonialsCarousel from "./CustomerTestimonialsCarousel";

const CustomerTestimonials = ({ landingPageTestimonials }) => {
  return (
    <Container size={"xl"} my="xl" py="md" bg="rgb(235, 245, 246, 0.7)">
      {/* <Divider my="lg" /> */}
      <Stack spacing={"0px"}>
        <Text fw={500} size={"sm"} color="pink">
          Beautiful Client Thoughts
        </Text>
        <Text fw={500} size={"1.5rem"}>
          What Our Clients Say
        </Text>
      </Stack>
      {landingPageTestimonials && (
        <CustomerTestimonialsCarousel
          landingPageTestimonials={landingPageTestimonials}
        />
      )}
      <Group position="right">
        <Button
          className="buttonOutline"
          component={Link}
          to="/addreview/admin"
          variant="outline"
          rightIcon={<IconArrowRight />}
        >
          Add Testimonial
        </Button>
      </Group>
    </Container>
  );
};

export default CustomerTestimonials;
