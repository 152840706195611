import React from "react";

const SearchBackgroundOpacityDiv = () => {
  return (
    <div
      style={{
        opacity: "55%",
        zIndex: 2,
        backgroundColor: "black",
        height: "100%",
        width: "100%",
        position: "absolute",
      }}
    ></div>
  );
};

export default SearchBackgroundOpacityDiv;
