import { Anchor, Button, Container, Divider, Group, Text } from "@mantine/core";
import { IconArrowRight } from "@tabler/icons";
import React from "react";
import { Link } from "react-router-dom";
import LandingPageStatsCarousel from "./LandingPageStatsCarousel";

const LandingPageStats = ({ landingPageStats, entry, ref }) => {
  console.log("$LandingPageVenues: ", landingPageStats);
  return (
    <Container size={"xl"} my="xl">
      <Group position="apart" mb="xl">
        <Text size={"1.5rem"} fw={500}>
          Our Statistics
        </Text>
      </Group>
      <LandingPageStatsCarousel
        landingPageStats={landingPageStats}
        entry={entry}
        ref={ref}
      />
    </Container>
  );
};

export default LandingPageStats;
